<template>
  <div class="PerformStep">
    <div class="content">
      <div class="introduce">
        <img
          class="introduce-pic"
          src="../../assets/image/投标保函.png"
          alt=""
        >
        <div class="introduce-text">
          <div class="text-tit">
            投标保函
          </div>
          <div class="text-detail">
            投标保函是指：在招标投标中招标人为保证投标人不得撤销投标文件、中标后不得无正当理由不与招标人订立合同等，要求投标人在提交投标文件时一并提交的一般由银行或担保公司出具的书面担保。当投标人在投标有效期内撤销投标，或者中标后不能同业主订立合同或不能提供履约保函时，担保银行或担保公司就需要负责付款。
          </div>
          <a-button
            class="text-btn"
            type="primary"
            @click="$router.push({ path: '/performance', query: { type: '2' } })"
          >
            立即申请
          </a-button>
        </div>
      </div>
      <div class="describe">
        <div class="describe-list">
          <div class="list-tit">
            一、产品定义：
          </div>
          <div class="list-text">
            投标保函是指：在招标投标中由保证人（银行或担保公司）为投标人向招标人提供的、保
            证投标人按照招标文件的规定参加招标活动的保函。投标人在投标有效期内撤回投标文件，
            或中标后不签署工程建设合同的，由保证人按照约定履行保证责任。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            二、担保方式：
          </div>
          <div class="list-text">
            ①银行保函<br>
            ②担保公司保函
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            三、担保额度:
          </div>
          <div class="list-text">
            投标保函办理的金额一般为投标报价的1%—5%。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            四、担保期限:
          </div>
          <div class="list-text">
            投标保函有效期一般为六个月以内，从开出之日起生效。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            五、申请条件：
          </div>
          <div class="list-text">
            1.招标文件资料（平台合作的公共资源交易中心不需要）;<br>
            2.营业执照电子版;<br>
            3.保函格式（平台合作的公共资源交易中心不需要）;<br>
            4.法人代表身份证电子版。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            六、申请方式：
          </div>
          <div class="list-text">
            1.电子保函通过本平台合作的公共资源交易中心提交申请;<br>
            2.纸质保函点击页面上方“立刻申请”。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.content{
  width: 1500px;
  margin: 0 auto;
  padding: 30px 140px;
}
.introduce{
    display: flex;
    margin-bottom: 50px;
}
.introduce-text{
    margin-left: 30px;
}
.text-tit{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 5px;
}
.text-detail{
    line-height: 32px;
    margin-left: 5px;
}
.describe-list{
    margin-bottom: 50px;
}
.list-tit{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.list-text{
     font-size: 14px;
     line-height: 30px;
}
</style>
